<template>
  <div>
    <b-row>
      <b-col class="mt-2"
        ><h3>{{ fragetext }}</h3></b-col
      >
    </b-row>
    <b-row v-if="hinweistext">
      <b-col class="mb-2">{{ hinweistext }}</b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="(opt, index) in optionen"
        :key="`${key}RV${index}`"
        :xs="xs"
        :sm="sm"
        :md="md"
        :lg="lg"
        :xl="xl"
      >
        <b-form-checkbox
          v-model="opt.checked"
          v-bind:checked="opt.checked"
          button
          button-variant="outline-dark"
          :name="`Frage_${Frage}`"
          class="mx-1 my-1"
          @change="handleChange(index)"
          :key="key"
          >{{ opt.text }}</b-form-checkbox
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fragetext: "Linien und Falten",
      hinweistext: undefined,
      optionen: [
        { text: "Augen (Krähenfüße)", checked: false },
        { text: "Lippenfältchen", checked: false },
        { text: "Nasolabial", checked: false },
        { text: "Stirn", checked: false },
        { text: "Generell im ganzen Gesicht", checked: false },
      ],
      xs: 12,
      sm: 6,
      md: 6,
      lg: 4,
      xl: 4,
      key: Math.random(),
    };
  },
  props: {
    Frage: { required: true },
  },
  watch: {
    selected() {
      this.handleChange();
    },
  },
  methods: {
    handleChange() {
      const selected = [];
      this.optionen.forEach((v) => {
        if (v.checked) {
          selected.push(v.value ? v.value : v.text);
        }
      });
      this.$store.commit("setAnwser", {
        Frage: this.Frage,
        Fragentext: this.fragetext,
        Antwort: selected,
        Antwort_2: undefined,
        Antwort_3: undefined,
      });
    },
  },
  beforeMount() {
    const answ = this.$store.getters.getAnwsers;
    if (answ[this.Frage - 1]) {
      this.optionen.forEach((v) => {
        if (answ[this.Frage - 1].Antwort.indexOf(v.text) >= 0) {
          v.checked = true;
        }
      });
    }
    this.key = Math.random();
  },
};
</script>